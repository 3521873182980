const hospitalTranslation = {
  mode: 'ko',
  menu: '병원',

  hospitalTableTitle: '병원 리스트',
  addHospital: '병원 추가',
  addHospitalRoom: '진료실 추가',
  addHospitalLogin: '계정 추가',
  seq: '번호',
  name: '이름',
  doctorName: '담당의',
  hospitalName: '병원명',
  zipcode: '우편번호',
  address: '상세 주소',
  city: '시/군/구',
  province: '시/도',
  telephone: '대표번호',
  description: '설명',
  viewable: '영상 & 초음파 보이기 상태 선택(선택시 보임)',
  recording: '초음파 영상',
  capture: '초음파 캡쳐',
  hospitalPermission: '병원 권한',
  nipt: 'NIPT',
  libre: 'LIBRE',
  useNipt: 'NIPT 사용',
  useLibre: 'LIBRE 사용',
  activeFlag: '활성 여부',
  activeStatus: '활성상태',
  active: '활성',
  inactive: '비활성',
  activeHospital: '활성 병원',
  inactiveHospital: '비활성 병원',
  id: '아이디',
  password: '비밀번호',
  permission: '권한',

  statistics: '통계',
  dailyMonthlyStatistics: '일/월별 통계',
  dailyMonthlyUltrasoundStatistics: '일/월별 초음파 통계',
  dailyStatistics: '일별 통계',
  monthlyStatistics: '월별 통계',
  dailyBarcodeCount: '일별 바코드 수',
  dailyVideoLengthAvg: '일별 초음파 영상 길이 평균',
  monthlyBarcodeCount: '월별 바코드 수',
  monthlyVideoLengthAvg: '월별 초음파 영상 길이 평균',

  record: '기록',
  detailRecord: '상세 기록',

  videoPlay: '영상 재생',
  videoDetailSearch: '영상 상세검색',
  videoDownload: '다운로드',
  deviceList: '장비 열람',
  niptAdmin: 'NIPT 관리',
  libreAdmin: 'LIBRE 관리',
  telemedicineAdmin: '원격진료 관리',
  statsDisplay: '지표 관리',
  medicalRecordDisplay: '진료기록 관리',
  videoBlind: '영상 블라인드',

  saveInfo: '정보 저장',
  info: '기본 정보',
  hospitalRoomInfo: '진료실 정보',
  hospitalLoginInfo: '병원용 관리자 계정 정보',
  editHospitalInfo: '병원 정보 수정',
  noRawPassword: '평문 없음',

  loginWarningMessage: '이미 사용하는 아이디입니다.',
  loginErrorMessage: '수정에 실패하였습니다. 관리자에게 문의해 주세요.',
  deleteHospitalRoomMessage: '진료실을 삭제하시겠습니까?',
  deleteLoginMessage: '계정을 삭제하시겠습니까?',
};

export default hospitalTranslation;
