const dashBoardTranslation = {
  mode: 'en',
  menu: 'Dashboard',
  recordingMenu: 'Recording Status',
  deviceMenu: 'Device Status',
  errorMenu: 'Error Status',

  recordingDailyCount: 'Daily Video Recorded',
  recordingMonthlyCount: 'Monthly Video Recorded',
  barcodeCountByHospital: 'Barcode Recorded by Account',
  deviceVersionCount: 'Device Version Status',
  inactiveDevice: 'Inactive Device List',
  updateDevice: 'Device List to be Updated',
  uninstalledDevice: 'Uninstalled Device List',
  offlineDevice: 'Offline Device List',
  capture: 'Captureboard Connection',
  upload: 'Upload',
  captureStatus: 'Captureboard Connection Status',
  uploadStatus: 'Upload Status',
  captureError: 'Captureboard Error Device List',
  uploadError: 'Failed Upload File List',

  renew: 'updatedAt',
  all: 'All',
  installed: 'Installed',
  online: 'Online',
  uninstalled: 'Uninstalled',
  offline: 'Offline',
  error: 'Captureboard Error',

  disconnected: 'Disconnected',
  connected: 'Connected',
  noInfo: 'No Info',
  fail: 'Fail',
  success: 'Success',

  hospital: 'Account',
  hospitalRoom: 'Location',
  device: 'Device',
  barcode: 'Barcode',
  city: 'State',
  barcodeCount: '# of Barocde Recorded',
  recordingCount: '# of Video Recorded',
  activity: 'Last Activity',
  version: 'Version',
  fileId: 'File ID',
  scannedAt: 'Scanned At',

  howToSearch: 'Search Help',
  help: 'Help',
  howToSearchMessage:
    'Shows inactive devices that have not been active since the selected date (default: inactive for 30 days)',
  howToUpdateMessage: 'Devices with version lower than 2.8 must be updated',
};

export default dashBoardTranslation;
