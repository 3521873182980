const hospitalTranslation = {
  mode: 'en',
  menu: 'Account',

  hospitalTableTitle: 'Account List',
  addHospital: 'Add Account',
  addHospitalRoom: 'Add Location',
  addHospitalLogin: 'Add HPA Account',
  seq: 'Location Seq',
  name: 'Location',
  doctorName: 'Admin Name',
  hospitalName: 'Account Name',
  zipcode: 'Zip Code',
  address: 'Address',
  city: 'City',
  province: 'State',
  telephone: 'Phone',
  description: 'Client Code',
  viewable: 'Viewable on Upload',
  recording: 'Video',
  capture: 'Photo',
  hospitalPermission: 'Admin Permission',
  nipt: 'NIPT',
  libre: 'LIBRE',
  useNipt: 'Allow NIPT',
  useLibre: 'Allow LIBRE',
  activeFlag: 'Active Status',
  activeStatus: 'Active?',
  active: 'Active',
  inactive: 'Inactive',
  activeHospital: 'Active Account',
  inactiveHospital: 'Inactive Account',
  id: 'ID',
  password: 'Password',
  permission: 'Permission',

  statistics: 'Account Performance',
  dailyMonthlyStatistics: 'Daily/Monthly Peformance',
  dailyMonthlyUltrasoundStatistics: 'Daily/Monthly Session Recorded',
  dailyStatistics: 'Daily Performance',
  monthlyStatistics: 'Monthly Performance',
  dailyBarcodeCount: 'Daily Barcode Recorded',
  dailyVideoLengthAvg: 'Daily Average Video Length',
  monthlyBarcodeCount: 'Monthly Barcode Recorded',
  monthlyVideoLengthAvg: 'Monthly Average Video Length',

  record: 'Records',
  detailRecord: 'Detailed Records',

  videoPlay: 'View Contents',
  videoDetailSearch: 'Search Contents',
  videoDownload: 'Download Contents',
  deviceList: 'View Device',
  niptAdmin: 'NIPT',
  libreAdmin: 'LIBRE',
  telemedicineAdmin: 'Telemedicine',
  statsDisplay: 'Dashboard',
  medicalRecordDisplay: 'Chart',
  videoBlind: 'Hide/Unhide Contents',

  saveInfo: 'Save',
  info: 'Account Information',
  hospitalRoomInfo: 'Location',
  hospitalLoginInfo: 'HPA Account',
  editHospitalInfo: 'Edit Account Information',
  noRawPassword: 'No Text',

  loginWarningMessage: 'ID already in use.',
  loginErrorMessage: 'Error: Failed to update. Please contact the administrator.',
  deleteHospitalRoomMessage: 'Do you want to delete this location?',
  deleteLoginMessage: 'Do you want to delete this HPA account?',
};

export default hospitalTranslation;
